import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-mdx";
import Image from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGraduationCap,
    faBuilding,
    faExternalLinkSquareAlt,
    faDatabase,
    faFileDownload,
    faChartPie,
    faChartLine,
    faCode,
    faPencilRuler,
} from "@fortawesome/free-solid-svg-icons";
import {
    faCalendarAlt,
    faCheckSquare,
} from "@fortawesome/free-regular-svg-icons";
import { faGithubSquare } from "@fortawesome/free-brands-svg-icons";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

import Layout from "../components/layout";
import { colors, border, mq } from "../assets/styles";

export const query = graphql`
    query($slug: String) {
        mdx(frontmatter: { slug: { eq: $slug } }) {
            frontmatter {
                title
                slug
                type
                client
                start
                end
                links {
                    live
                    github
                    download {
                        publicURL
                    }
                    data
                }
                tools
                categories
                projectImage {
                    sharp: childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                summary
            }
            code {
                body
            }
        }
    }
`;

const ProjectOverviewListItem = styled("li")`
    font-family: agenda, sans-serif;
    padding-left: 1.5em;
    text-indent: -1.5em;

    svg {
        margin-right: 0.25em;
        color: ${colors.blues[0]};
    }
`;

const ProjectLinkListItem = styled("li")`
    background-color: ${colors.neutrals[2]};
    font-family: agenda, sans-serif;
    color: white;
    text-align: center;
    text-transform: uppercase;

    a {
        padding: 0.5em;
        color: white;
        display: inline-block;
        width: 100%;

        svg {
            margin-right: 0.25em;
        }
    }
`;

const ProjectTemplate = ({ data: { mdx: project } }) => {
    const projectTypeLookup = {
        School: faGraduationCap,
        Professional: faBuilding,
    };

    const projectToolsLookup = {
        "Adobe Illustrator": faPencilRuler,
        "Adobe InDesign": faPencilRuler,
        "Adobe Photoshop": faPencilRuler,
        "Adobe XD": faPencilRuler,
        "D3.js": faChartPie,
        "Microsoft Power BI": faChartPie,
        Cytoscape: faChartPie,
        "Microsoft Excel": faChartLine,
        R: faChartLine,
        Python: faChartLine,
        SASS: faCode,
        jQuery: faCode,
        Bootstrap: faCode,
        "Node.js": faCode,
        Skeleton: faCode,
        Express: faCode,
    };

    const projectLinkLookup = {
        live: {
            wording: "Live project",
            icon: faExternalLinkSquareAlt,
        },
        github: {
            wording: "GitHub repo",
            icon: faGithubSquare,
        },
        data: {
            wording: "Data source",
            icon: faDatabase,
        },
        download: {
            wording: "PDF download",
            icon: faFileDownload,
        },
    };

    return (
        <Layout>
            <section
                css={css`
                    display: grid;
                    grid-gap: 1em;
                    align-items: start;

                    grid-template-areas:
                        "h1"
                        "image"
                        "overview"
                        "summary";

                    grid-template-rows: auto auto 1fr;

                    ${mq[1]} {
                        grid-template-areas:
                            "h1 h1"
                            "overview image"
                            "overview summary";
                    }
                `}
            >
                <h1
                    css={css`
                        grid-area: h1;
                    `}
                >
                    {project.frontmatter.title}
                </h1>
                <section
                    css={css`
                        grid-area: overview;
                        border: ${border};
                        padding: 1em;
                        margin-top: 0;

                        * + * {
                            margin-top: 0.75em;
                        }

                        h3 {
                            color: white;
                            margin-top: 1.5em;

                            span {
                                background-color: ${colors.blues[1]};
                                padding: 0.15em 0.3em;
                            }
                        }

                        ${mq[1]} {
                            width: 16em;
                        }
                    `}
                >
                    <h2>Project Overview</h2>
                    <div
                        css={css`
                            margin-top: 0;

                            ${mq[0]} {
                                display: flex;
                                flex-flow: row wrap;
                                justify-content: space-between;
                                align-content: flex-start;

                                section {
                                    margin-top: 0;
                                }
                            }

                            ${mq[1]} {
                                display: block;
                            }
                        `}
                    >
                        <section>
                            <h3>
                                <span>Summary</span>
                            </h3>
                            <ul>
                                <ProjectOverviewListItem>
                                    <FontAwesomeIcon
                                        icon={
                                            projectTypeLookup[
                                                project.frontmatter.type
                                            ]
                                        }
                                        fixedWidth
                                    />
                                    {project.frontmatter.client}
                                </ProjectOverviewListItem>
                                <ProjectOverviewListItem>
                                    <FontAwesomeIcon
                                        icon={faCalendarAlt}
                                        fixedWidth
                                    />
                                    {project.frontmatter.end
                                        ? project.frontmatter.start +
                                          " to " +
                                          project.frontmatter.end
                                        : project.frontmatter.start}
                                </ProjectOverviewListItem>
                            </ul>
                        </section>
                        <section>
                            <h3>
                                <span>Tools</span>
                            </h3>
                            <ul>
                                {project.frontmatter.tools.map(tool => (
                                    <ProjectOverviewListItem key={tool}>
                                        <FontAwesomeIcon
                                            icon={projectToolsLookup[tool]}
                                            fixedWidth
                                        />
                                        {tool}
                                    </ProjectOverviewListItem>
                                ))}
                            </ul>
                        </section>
                        <section>
                            <h3>
                                <span>Categories</span>
                            </h3>
                            <ul>
                                {project.frontmatter.categories.map(
                                    category => (
                                        <ProjectOverviewListItem key={category}>
                                            <FontAwesomeIcon
                                                icon={faCheckSquare}
                                                fixedWidth
                                            />
                                            {category}
                                        </ProjectOverviewListItem>
                                    )
                                )}
                            </ul>
                        </section>
                    </div>
                    <section>
                        <ul
                            css={css`
                                margin-top: 1.5em;
                            `}
                        >
                            {Object.keys(project.frontmatter.links).map(
                                linkType =>
                                    project.frontmatter.links[linkType] !=
                                        null && (
                                        <ProjectLinkListItem key={linkType}>
                                            <a
                                                href={
                                                    typeof project.frontmatter
                                                        .links[linkType] ===
                                                    "object"
                                                        ? project.frontmatter
                                                              .links[linkType][
                                                              "publicURL"
                                                          ]
                                                        : project.frontmatter
                                                              .links[linkType]
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        projectLinkLookup[
                                                            linkType
                                                        ]["icon"]
                                                    }
                                                    fixedWidth
                                                />
                                                {
                                                    projectLinkLookup[linkType][
                                                        "wording"
                                                    ]
                                                }
                                            </a>
                                        </ProjectLinkListItem>
                                    )
                            )}
                        </ul>
                    </section>
                </section>
                <Image
                    fluid={project.frontmatter.projectImage.sharp.fluid}
                    css={css`
                        grid-area: image;
                        margin-top: 0;
                    `}
                />
                <p
                    css={css`
                        grid-area: summary;
                        margin-top: 0;
                    `}
                >
                    {project.frontmatter.summary}
                </p>
            </section>
            <MDXRenderer>{project.code.body}</MDXRenderer>
        </Layout>
    );
};

export default ProjectTemplate;
